import JSBI from 'jsbi'

export const INIT_CODE_HASH = '0xb867696b5bd2f71b22deb9b185cbe6d8d2a7f806898dcab14ddae33d69b923b4'

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const FIVE = JSBI.BigInt(5)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)
